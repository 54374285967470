import { AuthState } from '@okta/okta-auth-js';
import { Server } from '../../Shared/Util/Server';

export function searchLinkedResponses(docId: number, pageNumber: number, itemsPerPage: number, sortOrder: string, auth: AuthState): Promise<Api.IResponseSearchResponse|null> {
  let url = `/response/search?documentId=${docId}&pageNumber=${pageNumber}&resultsPerPage=${itemsPerPage}`;
  if(sortOrder) {
    url = `${url}&sortOrder=${sortOrder}`;
  }
  return Server.get<Api.IResponseSearchResponse>(url, auth);
}
