import * as qs from 'qs';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import appHistory from '../AppHistory';
import { InvalidIntegerRedirector } from '../Shared/Util/InvalidIntegerRedirector';
import { SearchResponsePage } from './container/SearchResponsePage';


interface ISearchPageProps extends RouteComponentProps<any>{
  pageNumber: string;
  docId: string;
}

export const SearchResponses: React.FC<ISearchPageProps> = (props: ISearchPageProps) => {
  const searchParams = qs.parse(appHistory.location.search, { ignoreQueryPrefix: true });
  return (
    <InvalidIntegerRedirector numberStrings={[searchParams.docId, props.match.params.pageNumber]}>
      <SearchResponsePage {...{ docId: parseInt(searchParams.docId as string, 10), pageNumber: parseInt(props.match.params.pageNumber, 10), sortOrder: searchParams.sortOrder }} />
    </InvalidIntegerRedirector>
  );
};
