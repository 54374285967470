import { NavLink } from 'react-router-dom';
import './Sidebar.scss';
import '../../../../src/styles/_icons.scss';

export const Sidebar = () => (
  <nav className="sidebar" id='Sidebar'>
    <ul>
      <li>
        <SidebarLink linkTo="/home" icon="home" linkText="Home" />
      </li>
      <li>
        <SidebarLink linkTo="/manage" icon="report" linkText="Manage" />
      </li>
    </ul>
  </nav>
);

const SidebarLink = (props: {linkTo: string, linkText: string, icon: string}) => (
  <NavLink activeClassName="active" to={props.linkTo}><i className={`icon icon-${props.icon}`} />
    <span className="link-text">
      {props.linkText}
    </span>
  </NavLink>
);
