import { AuthState } from '@okta/okta-auth-js';
import * as classnames from 'classnames';
import * as React from "react";
import appHistory from "../../../AppHistory";
import i18n from "../../../i18n";
import { LoadingIndicator } from "../../../Shared/LoadingIndicator";
import { DORCTooltip } from "../../../Shared/Tooltip";
import { CompositeResponseList } from "../CompositeResponseList/CompositeResponseList";
import './SearchResponses.scss';

export interface ISearchResponsesProps {
  getResponsesLinkedToDocument: (docId: number, pageNumber: number, pageSize: number, sortOrder: string, auth: AuthState | null) => any;
  responses: Api.IPagedResult<Api.ICompositeResponseViewModel>;
  documentId: number;
  isDocumentIdValid: boolean;
  fetching: boolean;
  failed: boolean;
  searched: boolean;
  error: string;
  authState: AuthState | null;
}

export interface ISearchResponseState {
  docId: number,
  searchDisabled: boolean,
  isDocumentIdValid: boolean,
  error: string
}

class SearchResponses extends React.Component<ISearchResponsesProps, ISearchResponseState> {
  constructor(props: ISearchResponsesProps) {
    super(props);

    this.state = {
      searchDisabled: true,
      isDocumentIdValid: true,
      docId: props.documentId ? props.documentId : 0,
      error: !this.props.isDocumentIdValid ? i18n.t('manage.invalidDocIdMsg') : this.props.error
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.hanldeNewButtonClick = this.hanldeNewButtonClick.bind(this);
  }

  public componentDidUpdate(prevProps: ISearchResponsesProps) {
    if (prevProps && prevProps !== this.props) {
      if (this.props.documentId !== prevProps.documentId) {
        this.setState({
          docId: this.props.documentId,
          searchDisabled: this.props.documentId === 0,
          isDocumentIdValid: this.props.isDocumentIdValid,
          error: !this.props.isDocumentIdValid ? i18n.t('manage.invalidDocIdMsg') : this.props.error
        });
      }

      if (prevProps.isDocumentIdValid !== this.props.isDocumentIdValid
        && !this.props.isDocumentIdValid
        || (prevProps.error !== this.state.error)) {
        this.setState({
          error: !this.props.isDocumentIdValid ? i18n.t('manage.invalidDocIdMsg') : this.props.error
        });
      }
    }
  }

  public render() {
    const newButtonEnabled = this.props.searched && this.props.documentId > 0 && this.props.isDocumentIdValid && !!this.props.responses;
    //  const warningMsg = (this.props.searched && this.props.responses && !this.props.responses.rowCount)
    //    ?  i18n.t("manage.noResponseFoundMsg") : i18n.t("manage.createNewWarningMessage");

    return (
      <div>
        <LoadingIndicator busy={this.props.fetching} />
        {!this.props.fetching &&
          <ul className="search-response">
            <li className="info" data-value={i18n.t("numbers.one")}>
              <div className='header'>{i18n.t("manage.searchResponseTitle")}</div><div></div>
              <div className='description'>{i18n.t("manage.searchInstruction")}</div>
              <form noValidate={true} className="form-inline" onSubmit={this.handleSubmit}>
                <div className="input-group input-group-lg search-group">
                  <input type="text" aria-label="search-input" autoFocus={true} className={classnames('form-control', { 'is-invalid': this.state.error })}
                    value={this.state.docId === 0 ? '' : this.state.docId}
                    onChange={this.handleInputChange}
                    placeholder={i18n.t("manage.searchPlaceholderText")} />
                    <button type="submit" className="btn btn-primary" disabled={this.state.searchDisabled}>
                      {i18n.t("manage.search")}
                    </button>
                  <div className={classnames('invalid-feedback', {'no-display': this.props.isDocumentIdValid || this.props.responses.results.length !== 0})}>
                    {this.state.error}
                  </div>
                  { !this.props.isDocumentIdValid && this.props.responses.results.length > 0 && <DocumentDeletedNotification docId={this.props.documentId} />}
                </div>
              </form>
              {this.props.responses.rowCount > 0 &&
              <CompositeResponseList handlePageSizeChange={this.handlePageSizeChange} docId={this.props.documentId} responses={this.props.responses} authState={this.props.authState} />
            }
            </li>
            
            <li className="info" data-value={i18n.t("numbers.two")}>
              
              <div className='header'>{i18n.t("manage.createNewResponseTitle")}</div><div></div>
              <span className='description'>{i18n.t("manage.createNewResponseInstruction")}</span>
              <span className='information-circle'><DORCTooltip bootstrapIcon="icon-info-circle" tooltipText={i18n.t("manage.createNewWarningMessageTooltip")} placement="right" type="info"
                         iconClass="msg-tooltip-icon" triggerEvent="click" tooltipClass="cc-tooltip"/></span>


              <div className='new-response-btn'><button type="button"
                  disabled={!newButtonEnabled}
                  onClick={this.hanldeNewButtonClick}
                  className="create-new-btn btn btn-primary">
                  {i18n.t("manage.createNew")}
                </button>
                </div>

              
            </li>
          </ul>
        }
      </div>
    )
  }

  private handlePageSizeChange(pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState) {
    this.props.getResponsesLinkedToDocument(this.state.docId, pageNumber, itemPerPage, sortOrder, auth);
  }

  private hanldeNewButtonClick(e: React.FormEvent<HTMLButtonElement>) {
    appHistory.push(`/manage/0?documentId=${this.props.documentId || this.state.docId}`);
  }

  private handleInputChange(e: React.FormEvent<HTMLInputElement>) {
    if (!e.currentTarget.value) {
      this.setState({
        searchDisabled: true,
        docId: 0
      })
    } else {
      const documentId = parseInt(e.currentTarget.value, 10);
      if (documentId) {
        this.setState({
          searchDisabled: false,
          docId: parseInt(e.currentTarget.value, 10),
          error: ""
        })
      } else {
        this.setState({
          searchDisabled: true,
          docId: 0,
          error: i18n.t('manage.invalidDocIdMsg')
        });
      }
    }
  }

  private handleSubmit(event: any) {
    event.preventDefault();
    appHistory.push(`/manage/search/${this.props.responses.currentPage}?docId=${this.state.docId}`);
    this.props.getResponsesLinkedToDocument(this.state.docId, 1, this.props.responses.pageSize, this.props.responses.sortOrder, this.props.authState);
  }
}

function DocumentDeletedNotification(props: any) {
  return (
  <div className="document-deleted-notification">
    <i className="fa fa-exclamation" />
    <p className="notification-text">{i18n.t("manage.documentDeletedWithResponsesMessage", { docId: props.docId })}</p>
  </div>
  )
}

export { SearchResponses };
