import * as classNames from 'classnames';
import * as qs from 'qs';
import * as React from "react";
import appHistory from "../../../AppHistory";
import { DateTimeFormatter } from "../../../Shared/Util/DateTimeFormatter";

interface IResponseListRow {
  response: Api.ICompositeResponseViewModel
}

class CompositeResponseListRow extends React.Component<IResponseListRow> {
  constructor(props: IResponseListRow) {
    super(props);

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  public render() {
    const isDraft = this.props.response.status === 'Draft';
    return (
      <tr onClick={this.handleRowClick(isDraft)}>
        <td>{this.props.response.title}</td>
        <td className="response-status">
          <h5>
          <span className={classNames("badge", { "badge-secondary": isDraft, "badge-primary": !isDraft })}>
            {this.props.response.status}
          </span>
          </h5>

        </td>
        <td>{this.props.response.category}</td>
        <td>{DateTimeFormatter.ToShortDate(this.props.response.dateModified)}</td>
      </tr>
    )
  }

  private handleRowClick = (isDraft: boolean) => (e: any) => {
    const searchParams = qs.parse(appHistory.location.search, { ignoreQueryPrefix: true });
    appHistory.push(`/manage/${this.props.response.id}?documentId=${searchParams.docId}&isDraft=${isDraft}`);
  }
}

export { CompositeResponseListRow };
