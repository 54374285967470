import { Action, Dispatch } from 'redux';
import { searchLinkedResponses } from './Operations';
import { AuthState } from '@okta/okta-auth-js';
import { Action as AnyAction } from '@reduxjs/toolkit';

export enum SearchResponseActionTypes {
  SearchingResponses = 'SEARCH_RESPONSES_PENDING',
  SearchedResponses = 'SEARCHED_RESPONSES',
  SearchResponsesFailed = 'SEARCH_RESPONSES_FAILED',
  InitialiseResponseSearchState = 'INITIALISE_RESPONSESEARCH_STATE'
}

export class SearchingResponsesAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchingResponses;
}

export class InitialiseResponseSearhAction implements Action {
  public readonly type = SearchResponseActionTypes.InitialiseResponseSearchState;
}

export class SearchedResponsesAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchedResponses;
  constructor(public payload: {
    responses: Api.IPagedResult<Api.ICompositeResponseViewModel>,
    isDocumentIdValid: boolean,
    docId: number
  }) { }
}

export class SearchResponsesFailedAction implements Action {
  public readonly type = SearchResponseActionTypes.SearchResponsesFailed;
  constructor(public payload: { error: string }) { }
}

export type SearchResponsesAction = SearchingResponsesAction
  | SearchedResponsesAction
  | SearchResponsesFailedAction
  | InitialiseResponseSearhAction;

export const getLinkedResponsesThunk: (docId: number, pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState) => any = (docId, pageNumber, itemPerPage, sortOrder, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new SearchingResponsesAction());
    searchLinkedResponses(docId, pageNumber, itemPerPage, sortOrder, auth)
      .then((response: Api.IResponseSearchResponse) => {
        dispatch(new SearchedResponsesAction({ responses: response.compositeResponseList, docId, isDocumentIdValid: response.isDocumentIdValid }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new SearchResponsesFailedAction({ error: errorResponse.message }));
      });
  }
}
