import { AuthState } from "@okta/okta-auth-js";
import {withOktaAuth} from "@okta/okta-react";
import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import { manageResponseFormKey, sectionChangedClass } from "../../AppConstants";
import appHistory from "../../AppHistory";
import { validateAuthState } from "../../Auth/AuthHelper";
import i18n from "../../i18n";
import { IStoreState } from "../../Model";
import { LoadingIndicator } from "../../Shared/LoadingIndicator";
import { ConfirmModal } from "../../Shared/Modal/ConfirmModal";
import { FormInput } from "../../Shared/Util/FormInput";
import { FormSelect } from "../../Shared/Util/FormSelect";
import { HandleSubmitFailure } from "../../Shared/Util/HandleFormSubmitFail";
import { nameof } from "../../Shared/Util/NameOf";
import { Accordion } from "../components/Collapse/Accordion";
import { Collapse } from "../components/Collapse/Collapse";
import { renderMetadataFields } from "../components/MetadataFields/renderMetadataFields";
import QueryVariations from "../components/QueryVariations/QueryVariations";
import { ResponseSaved } from "../components/ResponseSaved/ResponseSaved";
import { ResponseVariations } from "../components/ResponseVariations/ResponseVariations";
import {
  ADraftAlreadyExistsNotification,
  ColumnPanel,
  CopyButton,
  MiddlePanel,
  PublishedDraftVersionNotification,
  ResponseBodyTextArea,
  SectionSelectionPanel,
  StepPanel,
} from "../components/SelectDoc/MarkupComponents";
import { ReduxSectionSelector } from "../components/SelectDoc/ReduxSectionSelector";
import {
  checkVersionMismatchThunk,
  copyDocumentSectionsThunk,
  deleteDraftResponseThunk,
  deleteResponseThunk,
  DismissErrorAction,
  fetchContentCurationThunk,
  fetchResponseVariationThunk,
  getChannelsThunk,
  getMetadataThunk,
  getPanvivaDocumentThunk,
  getResponseCategoriesThunk,
  initialiseFormThunk,
  RemoveRefreshResponseVariationIndexAction,
  ResetResponseVariationAction,
  saveDraftResponseThunk,
  saveResponseThunk,
  SetPanvivaDocumentSectionActionThunk,
  getKeywordsThunk,
  getQueryVariationsThunk,
  fetchSummaryThunk,
  ResetKeywordsAction,
  ResetQueryVariationsAction,
  ResetContentCurationAction,
  SetUndoSectionAction,
  ResetDocumentSectionUndoAction,
  SetUndoResponseAction,
  SetDocumentResponseAction,
  ResetDocumentResponseUndoAction
} from "../duck/Actions";
import { IManageResponseFormValues, IOrderedSection, ISection } from "../duck/Models";
import { fetchHasDraftResponse } from "../duck/Operations";
import {
  IManagePageDispatchProps,
  IManagePageOwnProps,
  IManageProps,
  IManageResponseState,
  IManageResponseStateProps,
} from "./ManageResponsePage.model";
import "./ManageResponsePage.scss";
import { validateManageResponseForm } from "./ManageResponsePage.validation";
import { Action as AnyAction } from "@reduxjs/toolkit";

class ManageResponsePage extends React.Component<
  IManageProps,
  IManageResponseState
> {
  constructor(props: IManageProps) {
    super(props);

    this.state = {
      saveModalVisible: false,
      cancelModalVisible: false,
      deleteModalVisible: false,
      errorModalVisible: false,
      saveDraftModalVisible: false,
      hasDraft: false,
      versionMismatch: false,
      isPrimaryQueryNull: false,
      navigatedPageStateForResponse: false,
      navigatedPageStateForSections: false
    };

    this.showSaveModal = this.showSaveModal.bind(this);
    this.showCancelModal = this.showCancelModal.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.showSaveDraftModal = this.showSaveDraftModal.bind(this);
    this.saveDialogHandler = this.saveDialogHandler.bind(this);
    this.cancelDialogHandler = this.cancelDialogHandler.bind(this);
    this.deleteDialogHandler = this.deleteDialogHandler.bind(this);
    this.errorDialogHandler = this.errorDialogHandler.bind(this);
    this.saveDraftDialogHandler = this.saveDraftDialogHandler.bind(this);
    this.saveAsDraftDialogHandler = this.saveAsDraftDialogHandler.bind(this);
    this.onCopySections = this.onCopySections.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.scrollToFirstChangedSection = this.scrollToFirstChangedSection.bind(
      this
    );
    this.checkForDraft = this.checkForDraft.bind(this);
    this.setNavigatedPageStateForSections = this.setNavigatedPageStateForSections.bind(this);

    // Only check for existing draft when editing an existing response
    if (this.props.id !== "0" && !this.props.isDraft) {
      this.checkForDraft();
    } else if (this.props.id !== "0" && this.props.isDraft) {
      this.props.checkVersionMismatch(
        this.props.id,
        this.props.authState
      );
    }
  }

  public componentDidMount() {
    if (this.props.authState?.isAuthenticated) {
      this.props.initialise(
        this.props.id || "0",
        this.props.documentId,
        this.props.isDraft,
        this.props.authState
      );
    }
    validateAuthState(this.props.authState);
  }

  public componentDidUpdate(prevProps: IManageProps) {
    if (this.props.authState?.isAuthenticated) {
      if (
        prevProps &&
        ((!prevProps.deletedResponse && this.props.deletedResponse) ||
          (!prevProps.draftResponseSaved && this.props.draftResponseSaved))
      ) {
        // Now that we can open responses and drafts from within a response it's no longer practical
        // to use appHistory.goBack(), instead we want to go back to the appropriate list upon saving or deleting.
        // There is a chance that previously a user would be taken back to the updates list which will no longer happen.
        appHistory.push("/home/draftresponses/1");
      }
    }
    else {
      validateAuthState(this.props.authState);
    }

    if (prevProps && prevProps.fetchedResponse !== this.props.fetchedResponse) {
      if (!this.props.isDraft) {
        this.scrollToFirstChangedSection();
      }
    }
    if (this.props.fetchedResponseVariation || this.props.fetchedContentCuration || this.props.fetchedKeywords || this.props.fetchedQueryVariations || this.props.undoSectionOperationCompleted || this.props.undoResponseOperationCompleted) {
      this.props.initialize({
        ...this.props.initialValues
      });
      this.props.resetResponseVariation();
      this.props.resetContentCuration();
      this.props.resetKeywords();
      this.props.resetQueryVariations();
      this.props.resetDocumentSectionUndo();
      this.props.resetDocumentResponseUndo();
    }
  }

  static getDerivedStateFromProps(nextProps: IManageProps) {
    if (nextProps.formValues?.primaryQuery == null || nextProps.formValues?.primaryQuery == undefined || nextProps.formValues?.primaryQuery == "") {
      return ({ isPrimaryQueryNull: true }) 
    } else {
      return ({ isPrimaryQueryNull: false }) 
    }
  }

  public render() {
    if (!this.props.authState) {
      return (
        <LoadingIndicator
          busy={true}
        />
      );
    }
    if (this.props.authState.error) {
      throw this.props.authState.error;
    }

    let availableChannels: Api.IChannelViewModel[] = this.props.channels;
    if (
      this.props.channels.length &&
      this.props.formValues &&
      this.props.formValues.responseVariations &&
      this.props.formValues.responseVariations.length
    ) {
      const assignedChannels = this.props.formValues.responseVariations.reduce(
        (a: Api.IChannelViewModel[], b: Api.IResponseVariationViewModel) =>
          a.concat(b.channels),
        []
      );

      availableChannels = this.props.channels.filter(
        (x: any) => !assignedChannels.some((y: any) => x.name === y.name)
      );
    }

    if (this.props.formValues && this.props.responseSaved) {
      return (
        <ResponseSaved
          title={this.props.formValues.title}
          category={this.props.formValues.category}
        />
      );
    }

    const pageTitle =
      this.props.id === "0"
        ? i18n.t("manage.pageTitleWhenNew")
        : this.props.isDraft
          ? i18n.t("manage.pageTitleWhenEditingDraft")
          : i18n.t("manage.pageTitleWhenEditing");

    const documentSelectionHeading =
      this.props.id === "0"
        ? i18n.t("manage.documentSelection.responseHeadingWhenNew")
        : i18n.t("manage.documentSelection.responseHeadingWhenEditing");

    const responseDescriptionHeading =
      this.props.id === "0"
        ? i18n.t("manage.documentSelection.responseDescriptionWhenNew")
        : i18n.t("manage.documentSelection.responseDescriptionWhenEditing");

    let parsedMetadata = JSON.parse(JSON.stringify(this.props.metadata));
    return (

      <div className="manage-responses">
        <ADraftAlreadyExistsNotification hasDraft={this.state.hasDraft} />
        <PublishedDraftVersionNotification
          versionMismatch={this.props.versionMismatch}
        />
        <LoadingIndicator busy={this.isLoading()} />
        <div className="page-title manage-draft">
          <h1>{pageTitle}</h1>
          <span className="required-fields">
            {i18n.t("manage.requiredFields")}
          </span>
        </div>
        <form className="response-form">
          <div className="form-fields title">
            <Field
              name={nameof<IManageResponseFormValues>("title")}
              type="text"
              props={{
                required: true,
                tooltipText: i18n.t("manage.tooltips.title"),
                badgeText: this.props.isDraft
                  ? i18n.t("manage.response.draftBadge")
                  : "",
              }}
              component={FormInput}
              label={i18n.t("manage.response.title")}
            />

            <div className="form-fields">
              <Field
                name={nameof<IManageResponseFormValues>("primaryQuery")}
                type="text"
                props={{
                  required: true,
                  tooltipText: i18n.t("manage.tooltips.primaryQuery"),
                }}
                component={FormInput}
                label={i18n.t("manage.response.primaryQuery")}
              />
            </div>

            <div className="doc-id">
              <div className="doc-number">
                <strong>{i18n.t("manage.documentId")}:</strong>
                <span>{this.props.documentId}</span>
              </div>
              <div className="doc-title">
                <strong>{i18n.t("manage.documentTitle")}:</strong>
                <span>{this.props.panvivaDocumentTitle}</span>
              </div>
            </div>
          </div>

          <SectionSelectionPanel>
            <ColumnPanel>
              <StepPanel
                stepNumber="1"
                heading={i18n.t("manage.documentSelection.selectorHeading")}
                showStar={true}
              >
                <p>{i18n.t("manage.documentSelection.selectorDescription")}</p>
                <Field
                  name={nameof<IManageResponseFormValues>("taggedSections")}
                  component={ReduxSectionSelector}
                  {...{
                    sections: this.props.undoSections[this.props.undoSections.length-1],
                    sectionDeleted: this.props.isSectionDeleted,
                    panvivaDocumentDeleted: this.props.panvivaDocumentNotFound,
                    fetchingPanvivaDocument: this.props
                      .fetchingDocumentSections,
                    disabledContentCuration: !(this.props.formValues?.primaryQuery ? this.props.formValues.primaryQuery.trim() : false),
                    fetchContentCuration: this.fetchContentCuration,
                    setPanvivaDocumentSections: this.props.setPanvivaDocumentSections,
                    enableAIAssistant: this.props.featureSettings.enableAIAssistant && this.props.panvivaDocumentSections.length,
                    undoSections: this.props.undoSections,
                    undoSectionAction: this.undoSectionAction,
                    navigatedPageStateForSections: this.state.navigatedPageStateForSections,
                    setNavigatedPageStateForSections:this.setNavigatedPageStateForSections
                  }}
                />
              </StepPanel>
            </ColumnPanel>
            <MiddlePanel>
              <CopyButton onClick={this.onCopySections} />
            </MiddlePanel>
            <ColumnPanel>
              <StepPanel
                stepNumber="2"
                heading={documentSelectionHeading}
                showStar={true}
              >
                <p>{responseDescriptionHeading}</p>
                <Field
                  name={nameof<IManageResponseFormValues>("primaryResponse")}
                  component={ResponseBodyTextArea}
                  {...{
                    disableSummariseResponse: !this.props.formValues?.primaryResponse?.trim(),
                    fetchSummaryResponse: this.fetchSummaryResponse,
                    enableAIAssistant: this.props.featureSettings.enableAIAssistant && this.props.panvivaDocumentSections.length,
                    wandIconTooltipText: i18n.t('manage.tooltips.wandiconTooltipEditResponse'),
                    undoResponses: this.props.undoResponses,
                    undoResponseAction: this.undoResponseAction,
                    onInputChange: this.onInputChange,
                    navigatedPageStateForResponse: this.state.navigatedPageStateForResponse
                  }}
                  onBlur={(event: Event | any) => event.target.value && this.fetchSummaryResponse(event.target.value)}
                />
              </StepPanel>
            </ColumnPanel>
          </SectionSelectionPanel>

          <StepPanel stepNumber="3" heading={i18n.t("manage.editDetails")} />
          <div className="response-details">
            <div className="response-details-left">
              <div className="form-fields">
                <Field
                  name={nameof<IManageResponseFormValues>("category")}
                  type="select"
                  props={{
                    required: true,
                    tooltipText: i18n.t("manage.tooltips.category"),
                  }}
                  component={FormSelect}
                  label={i18n.t("manage.response.category")}
                >
                  <option
                    value=""
                    disabled={true}
                    hidden={true}
                    style={{ display: "none" }}
                  >
                    {i18n.t("manage.selectCategory")}
                  </option>
                  {this.props.fetchedCategories &&
                    this.props.categories &&
                    this.props.categories.map((x: any) => {
                      return (
                        <option key={x.id} value={x.categoryName}>
                          {x.categoryName}
                        </option>
                      );
                    })}
                </Field>
              </div>

              <Accordion id="queryVariationsSection">
                <Collapse
                  title={i18n.t("manage.response.queryVariations")}
                  id="queryvariations"
                  tooltipText={i18n.t("manage.tooltips.queryVariations")}
                  parentId="queryVariationsSection"
                  showWandIcon={this.props.featureSettings.enableAIAssistant}
                  fetchData={(numberto: number) => { this.fetchQueryVariation(numberto); }}
                  disableWandIcon={!(this.props.formValues?.primaryQuery ? this.props.formValues.primaryQuery.trim() : false)}
                  wandIconTooltipText={i18n.t("manage.tooltips.wandiconQueryVariations")}
                >
                  <QueryVariations
                    name={nameof<IManageResponseFormValues>("queryVariations")}
                    onRefresh={(numberto: number, index: number) => { this.props.getQueryVariations(this.props.formValues?.primaryQuery, numberto, this.props.formValues.queryVariations, index, this.props.formValues); }}
                    showWandIcon={this.props.featureSettings.enableAIAssistant}
                  />
                </Collapse>
              </Accordion>

              {this.props.metadata &&
                this.props.formValues &&
                // Loop over the metadata in the order their values were saved, otherwise the fields may get rendered with incorrect values
                [...parsedMetadata]
                  .map((x) => { 
                    x.getKeywords = this.fetchKeywords;
                    x.showWandIcon =  this.props.featureSettings.enableAIAssistant;
                    x.primaryResponse = this.props.formValues?.primaryResponse;
                    return x;
                  })
                  .map(renderMetadataFields)
                  .sort((a, b) => a.index - b.index)
                  .map((x) => x.component)}

              <Accordion id="responseVariationsSection">
                <Collapse
                  title={i18n.t("manage.response.responseVariations")}
                  tooltipText={i18n.t("manage.tooltips.responseVariations")}
                  id="ResponseVariations"
                  parentId="responseDetailSection"
                  showWandIcon={false}
                  fetchData={() => { }}
                  disableWandIcon={false}
                  wandIconTooltipText={""}
                >
                  <FieldArray
                    name={nameof<IManageResponseFormValues>(
                      "responseVariations"
                    )}
                    props={{
                      channels: availableChannels,
                      touch: this.props.touch,
                    }}
                    component={ResponseVariations}
                    {...{
                      disabledAIAssistant: !this.props.formValues?.primaryResponse?.trim(),
                      fetchResponseVariation: this.fetchResponseVariation,
                      showAIAssistant: this.props.featureSettings.enableAIAssistant,
                      refreshResponseVariations: this.props.refreshResponseVariations,
                      removeRefreshIndex:  this.props.removeResponseVariationIndex
                    }}
                  />
                </Collapse>
              </Accordion>
            </div>

            {/* <div className="response-details-right">
              
            </div> */}
          </div>

          <div className="manage-footer">
            <button
              type="button"
              className="btn btn-outline-secondary btn-outline-danger btn-lg"
              hidden={this.props.id === "0"}
              onClick={this.showDeleteModal}
            >
              {this.props.isDraft
                ? i18n.t("manage.deleteDraft")
                : i18n.t("manage.delete")}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-lg"
              onClick={this.handleCancelButtonClick}
            >
              {i18n.t("manage.cancel")}
            </button>

            <button
              type="button"
              className="btn btn-outline-secondary btn-lg"
              disabled={
                this.props.disableSave ||
                this.isLoading() ||
                this.state.hasDraft
              }
              onClick={this.showSaveDraftModal}
            >
              {this.props.isDraft
                ? i18n.t("manage.saveDraft")
                : i18n.t("manage.createDraft")}
            </button>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              disabled={this.props.disableSave || this.isLoading()}
              onClick={this.showSaveModal}
            >
              {i18n.t("manage.publish")}
            </button>
          </div>
        </form>

        {this.renderDeleteModal()}
        {this.renderSaveModal()}
        {this.renderCancelModal()}
        {this.renderErrorModal()}
        { this.props.errorAIDetails?.title && this.renderAIAssistantErrorModal()}
        {this.renderSaveDraftModal()}
      </div>
    );
  }

  private scrollToFirstChangedSection() {
    window.requestAnimationFrame(() => {
      const changedSections = document.getElementsByClassName(
        sectionChangedClass
      );
      const wrapper = document.querySelector(".section-selector");
      if (changedSections && changedSections.length > 0) {
        const top =
          changedSections[0].getBoundingClientRect().top -
          wrapper!.getBoundingClientRect().top -
          20;
        wrapper!.scrollTop += top;
        setTimeout(() => {
          changedSections?.length > 0 && changedSections[0].classList.add("animate");
          setTimeout(() => {
            changedSections?.length >0 && changedSections[0].classList.remove("animate");
          }, 800);
        }, 600);
      }
    });
  }

  private isLoading(): boolean {
    return (
      this.props.fetchingCategories ||
      this.props.fetchingDocumentSections ||
      this.props.fetchingResponse ||
      this.props.savingResponse ||
      this.props.fetchingChannels ||
      this.props.deletingResponse ||
      this.props.fetchingContentCuration ||
      this.props.fetchingResponseVariation ||
      this.props.fetchingQueryVariations ||
      this.props.fetchingKeywords ||
      this.props.fetchSummaryPending ||
      (!this.props.authState?.isAuthenticated && !this.props.authState?.error)
    );
    // TODO: use global interceptor to show loading spinner. otherwise this list/conditions will increase in future.
  }

  private renderDeleteModal() {
    const title = this.props.isDraft
      ? i18n.t("manage.dialogs.deleteDraftConfirmation")
      : i18n.t("manage.dialogs.deleteConfirmation");
    const message = this.props.isDraft
      ? i18n.t("manage.dialogs.deleteDraftDialogMessage")
      : i18n.t("manage.dialogs.deleteDialogMessage");
    return (
      <ConfirmModal
        onConfirm={this.deleteDialogHandler}
        title={title}
        visible={this.state.deleteModalVisible}
        cancelBtnText={i18n.t("modal.dialog.cancel")}
        confirmBtnText={i18n.t("modal.dialog.delete")}
      >
        {message}
      </ConfirmModal>
    );
  }

  private renderSaveDraftModal() {
    return (
      <ConfirmModal
        onConfirm={
          this.props.isDraft
            ? this.saveDraftDialogHandler
            : this.saveAsDraftDialogHandler
        }
        title={i18n.t("manage.dialogs.saveDraftConfirmation")}
        visible={this.state.saveDraftModalVisible}
        cancelBtnText={i18n.t("modal.dialog.cancel")}
        confirmBtnText={i18n.t("modal.dialog.saveDraft")}
      >
        {this.props.isDraft
          ? i18n.t("manage.dialogs.saveDraftDialogMessage")
          : i18n.t("manage.dialogs.saveAsDraftDialogMessage")}
      </ConfirmModal>
    );
  }

  private renderCancelModal() {
    return (
      <ConfirmModal
        onConfirm={this.cancelDialogHandler}
        title={i18n.t("manage.dialogs.cancelConfirmation")}
        visible={this.state.cancelModalVisible}
      >
        {i18n.t("manage.dialogs.cancelDialogMessage")}
      </ConfirmModal>
    );
  }

  private renderSaveModal() {
    return (
      <ConfirmModal
        visible={this.state.saveModalVisible}
        onConfirm={this.saveDialogHandler}
        title={i18n.t("manage.dialogs.publishConfirmation")}
        confirmBtnText={i18n.t("modal.dialog.publish")}
        cancelBtnText={i18n.t("modal.dialog.cancel")}
      >
        {i18n.t("manage.dialogs.publishDialogMessage")}
      </ConfirmModal>
    );
  }

  private renderErrorModal() {
    let modalTitle = "";
        if (this.props.failedSavingResponse) {
      modalTitle = i18n.t("manage.dialogs.error.saveErrorConfirmation");
    } else if (this.props.deleteResponseFailed) {
      modalTitle = i18n.t("manage.dialogs.error.deleteErrorConfirmation");
    }

    return (
      <ConfirmModal
        visible={modalTitle !== ""}
        onConfirm={this.errorDialogHandler}
        title={modalTitle}
        confirmBtnText={i18n.t("manage.ok")}
        onClickBackdrop={this.errorDialogHandler}
        allowCancel={false}
      >
        {this.props.errorMessage}
      </ConfirmModal>
    );
  }

  private renderAIAssistantErrorModal() {
    const { beforeText, afterText, htmlText, type, url } = this.props.errorAIDetails.html || {};
    return (
      <ConfirmModal
        visible={true}
        onConfirm={this.errorDialogHandler}
        title={this.props.errorAIDetails.title}
        confirmBtnText={i18n.t("manage.ok")}
        onClickBackdrop={this.errorDialogHandler}
        allowCancel={false}
      >
        { type === "link" ? 
            <p>{beforeText}<a href={url} target="_blank">{htmlText}</a>{afterText}</p>
            :
            this.props.errorAIDetails.message}
      </ConfirmModal>
    );
  }

  private onCopySections(evt: any) {
    this.props.copyDocumentSections(this.props.formValues);
  }

  private saveDialogHandler(confirmed: boolean) {
    if (confirmed) {
      this.props.handleSubmit(() => {
        validateManageResponseForm(this.props.formValues);

        this.props.saveResponse(
          this.props.formValues,
          this.props.panvivaDocumentId,
          this.props.panvivaDocumentVersion,
          this.props.id || "0",
          this.props.isDraft,
          this.props.authState
        );
      })();
    }

    this.setState({ saveModalVisible: false });
  }

  private saveDraftDialogHandler(confirmed: boolean) {
    if (confirmed) {
      this.props.handleSubmit(() => {
        validateManageResponseForm(this.props.formValues, true);

        this.props.saveDraftResponse(
          this.props.formValues,
          this.props.panvivaDocumentId,
          this.props.panvivaDocumentVersion,
          this.props.id || "0",
          this.props.authState
        );
      })();
    }

    this.setState({ saveDraftModalVisible: false });
  }

  private saveAsDraftDialogHandler(confirmed: boolean) {
    if (confirmed) {
      this.props.handleSubmit(() => {
        validateManageResponseForm(this.props.formValues, true);

        this.props.saveAsDraftResponse(
          this.props.formValues,
          this.props.panvivaDocumentId,
          this.props.panvivaDocumentVersion,
          this.props.id || "0",
          this.props.authState
        );
      })();
    }

    this.setState({ saveDraftModalVisible: false });
  }

  private cancelDialogHandler(confirmed: boolean) {
    if (confirmed) {
      appHistory.goBack();
    }

    this.setState({ cancelModalVisible: false });
  }

  private errorDialogHandler() {
    this.props.dismissError();
  }

  private deleteDialogHandler(confirmed: boolean) {
    if (confirmed) {
      if (!this.props.isDraft) {
        this.props.deleteResponse(this.props.id, this.props.authState);
      } else {
        this.props.deleteDraftResponse(
          this.props.id,
          this.props.authState
        );
      }
    }

    this.setState({ deleteModalVisible: false });
  }

  private showSaveModal = () => {
    this.setState({ saveModalVisible: true });
  };

  private showCancelModal = () => {
    this.setState({ cancelModalVisible: true });
  };

  private showDeleteModal = () => {
    this.setState({ deleteModalVisible: true });
  };

  private showSaveDraftModal = () => {
    this.setState({ saveDraftModalVisible: true });
  };

  private handleCancelButtonClick = () => {
    if (
      (this.props.initialValues ||
        !this.isFormValuesInitialValues(this.props.formValues)) &&
      !this.isFormPristine()
    ) {
      this.showCancelModal();
    } else {
      appHistory.goBack();
    }
  };

  private isFormValuesInitialValues = (values: IManageResponseFormValues) => {
    const expectedInitValues = { queryVariations: [undefined] };
    return JSON.stringify(values) === JSON.stringify(expectedInitValues);
  };

  private isFormPristine = () => {
    const filteredFormValues = {
      ...this.props.formValues,
      queryVariations: this.props.formValues.queryVariations.filter(Boolean),
    };
    return (
      JSON.stringify(this.props.initialValues) ===
      JSON.stringify(filteredFormValues)
    );
  };

  private checkForDraft = () => {
    fetchHasDraftResponse(this.props.id, this.props.authState)
      .then((x: Api.IHasDraftResponseResponse) => {
        this.setState({ hasDraft: x.hasDraft && !this.props.isDraft });
      })
      .catch((e: Error) => {
        this.setState({ hasDraft: true }); // If the call fails disable save as draft button
      });
  };

  private fetchContentCuration = () => {
    this.props.fetchContentCuration(this.props.formValues?.primaryQuery, this.props.panvivaDocumentSections, this.props.formValues);
  }

  private fetchResponseVariation = (channels: string[] = [], index: number) => {
    this.props.fetchResponseVariation(this.props.formValues?.primaryResponse, channels, index, this.props.formValues);
  }

  private fetchQueryVariation = (numberto: number) => {
    this.props.getQueryVariations(this.props.formValues?.primaryQuery, numberto, this.props.formValues?.queryVariations, 0, this.props.formValues);
  }

  private fetchKeywords = () => {
    this.props.getKeywords(this.props.formValues);
  }

  private fetchSummaryResponse = (primaryResponse:string) => {
    this.props.fetchSummaryResponse(primaryResponse, this.props.formValues);
  }

  private undoSectionAction = () => {
    if (this.props.undoSections.length > 1) {
      this.props.setUndoSection(this.props.formValues);
    }
  }

  private undoResponseAction = () => {
    if (this.props.undoResponses.length > 1) {
      this.props.setUndoResponse(this.props.formValues);
    }
  }

  private onInputChange = (e: string) => {
    this.setState({ navigatedPageStateForResponse: false });

    if (this.props.undoResponses.length == 0 || this.props.responseAddedAfterContentCuration || this.props.responseAddedbySummarizeButton || this.props.responseAddedByCopySections) {
      this.props.setDocumentResponse(e, false);
    }

    if (!this.props.responseAddedAfterContentCuration && !this.props.responseAddedbySummarizeButton) {
      this.props.setDocumentResponse(e, true);
    }
  }

  private setNavigatedPageStateForSections() {
    this.setState({ navigatedPageStateForSections: false });
  }
  }

const mapStateToProps = (state: IStoreState) => {
  return {
    panvivaDocumentSections: state.manageResponse.panvivaDocumentSections,
    fetchedCategories: state.manageResponse.fetchedCategories,
    fetchingCategories: state.manageResponse.fetchingCategories,
    failedCategories: state.manageResponse.failedCategories,
    fetchedResponse: state.manageResponse.fetchedResponse,
    fetchingResponse: state.manageResponse.fetchingResponse,
    failedResponse: state.manageResponse.failedResponse,
    fetchedDocumentSections: state.manageResponse.fetchedDocumentSections,
    fetchingDocumentSections: state.manageResponse.fetchingDocumentSections,
    failedDocumentSections: state.manageResponse.failedDocumentSections,
    failedSavingResponse: state.manageResponse.failedSavingResponse,
    panvivaDocumentNotFound: state.manageResponse.panvivaDocumentNotFound,
    fetchedChannels: state.manageResponse.fetchedChannels,
    fetchingChannels: state.manageResponse.fetchingChannels,
    fetchChannelsFailed: state.manageResponse.fetchChannelsFailed,
    fetchedMetadata: state.manageResponse.fetchedMetadata,
    fetchingMetadata: state.manageResponse.fetchingMetadata,
    fetchMetadataFailed: state.manageResponse.fetchMetadataFailed,
    fetchedKeywords: state.manageResponse.fetchedKeywords,
    fetchingKeywords: state.manageResponse.fetchingKeywords,
    fetchKeywordsFailed: state.manageResponse.fetchKeywordsFailed,
    fetchedQueryVariations: state.manageResponse.fetchedQueryVariations,
    fetchingQueryVariations: state.manageResponse.fetchingQueryVariations,
    fetchQueryVariationsFailed: state.manageResponse.fetchQueryVariationsFailed,
    errorMessage: state.manageResponse.error,
    errorAIDetails: state.manageResponse.errorAIDetails,
    categories: state.manageResponse.categories,
    fetchedContentCuration: state.manageResponse.fetchedContentCuration,
    fetchingContentCuration: state.manageResponse.fetchingContentCuration,
    fetchContentCurationFailed: state.manageResponse.fetchContentCurationFailed,
    formValues: getFormValues(manageResponseFormKey)(
      state
    ) as IManageResponseFormValues,
    responseSaved: state.manageResponse.responseSaved,
    panvivaDocumentId: state.manageResponse.panvivaDocumentId,
    panvivaDocumentVersion: state.manageResponse.panvivaDocumentVersion,
    initialValues: state.manageResponse.initialFormValues,
    savingResponse: state.manageResponse.savingResponse,
    disableSave: state.manageResponse.disableSave,
    channels: state.manageResponse.channels,
    metadata: state.manageResponse.metadata,
    deletingResponse: state.manageResponse.deletingResponse,
    deletedResponse: state.manageResponse.deletedResponse,
    deleteResponseFailed: state.manageResponse.deleteResponseFailed,
    draftResponseSaved: state.manageResponse.draftResponseSaved,
    isSectionDeleted: state.manageResponse.isSectionDeleted,
    panvivaDocumentTitle: state.manageResponse.panvivaDocumentTitle,
    version: state.manageResponse.version,
    checkingVersionMismatch: state.manageResponse.checkingVersionMismatch,
    checkedVersionMismatch: state.manageResponse.checkedVersionMismatch,
    checkVersionMismatchFailed: state.manageResponse.checkVersionMismatchFailed,
    versionMismatch: state.manageResponse.versionMismatch,
    featureSettings: state.tenants.featureSettings,
    fetchedResponseVariation: state.manageResponse.fetchedResponseVariation,
    fetchingResponseVariation: state.manageResponse.fetchingResponseVariation,
    fetchResponseVariationFailed: state.manageResponse.fetchResponseVariationFailed,
    refreshResponseVariations: state.manageResponse.refreshResponseVariationIndexes,
    fetchSummarySuccess: state.manageResponse.fetchSummarySuccess,
    fetchSummaryPending: state.manageResponse.fetchSummaryPending,
    fetchSummaryFailed: state.manageResponse.fetchSummaryFailed,
    undoSections: state.manageResponse.undoSections,
    undoSectionOperationCompleted: state.manageResponse.undoSectionOperationCompleted,
    undoResponses: state.manageResponse.undoResponses,
    undoResponseOperationCompleted: state.manageResponse.undoResponseOperationCompleted,
    responseAddedbySummarizeButton: state.manageResponse.responseAddedbySummarizeButton,
    responseAddedAfterContentCuration: state.manageResponse.responseAddedAfterContentCuration,
    responseAddedByCopySections: state.manageResponse.responseAddedByCopySections
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: IManagePageOwnProps) => {
  return {
    getPanvivaDocument: (documentId: number, auth: AuthState) => {
      dispatch(getPanvivaDocumentThunk(documentId, auth));
    },
    initialise: (
      responseId: string,
      documentId: number,
      isDraft: boolean,
      auth: AuthState
    ) => {
      dispatch(initialiseFormThunk(responseId, documentId, isDraft, auth));
    },
    getResponseCategories: (auth: AuthState): any => {
      dispatch(getResponseCategoriesThunk(auth));
    },
    saveResponse: (
      values: IManageResponseFormValues,
      panvivaDocumentId: string,
      panvivaDocumentVersion: number,
      responseId: string,
      isDraft: boolean,
      auth: AuthState
    ) => {
      dispatch(
        saveResponseThunk(
          values,
          panvivaDocumentId,
          panvivaDocumentVersion,
          responseId,
          auth,
          isDraft
        )
      );
    },
    copyDocumentSections: (formValues: IManageResponseFormValues) => {
      dispatch(copyDocumentSectionsThunk(formValues));
    },
    deleteResponse: (responseId: string, auth: AuthState) => {
      dispatch(deleteResponseThunk(responseId, auth));
    },
    getChannels: (auth: AuthState) => {
      dispatch(getChannelsThunk(auth));
    },
    getMetadata: (auth: AuthState) => {
      dispatch(getMetadataThunk(auth));
    },
    dismissError: () => {
      dispatch(new DismissErrorAction());
    },
    saveDraftResponse: (
      values: IManageResponseFormValues,
      panvivaDocumentId: string,
      panvivaDocumentVersion: number,
      responseId: string,
      auth: AuthState
    ) => {
      dispatch(
        saveDraftResponseThunk(
          values,
          panvivaDocumentId,
          panvivaDocumentVersion,
          responseId,
          auth
        )
      );
    },
    saveAsDraftResponse: (
      values: IManageResponseFormValues,
      panvivaDocumentId: string,
      panvivaDocumentVersion: number,
      responseId: string,
      auth: AuthState
    ) => {
      dispatch(
        saveDraftResponseThunk(
          values,
          panvivaDocumentId,
          panvivaDocumentVersion,
          responseId,
          auth,
          true
        )
      );
    },
    deleteDraftResponse: (draftResponseId: string, auth: AuthState) => {
      dispatch(deleteDraftResponseThunk(draftResponseId, auth));
    },
    checkVersionMismatch: (draftResponseId: string, auth: AuthState) => {
      dispatch(checkVersionMismatchThunk(draftResponseId, auth));
    },
    fetchContentCuration: (primaryQuery: string, documentContent: ISection[], formValues: IManageResponseFormValues) => {
      //@ts-ignore
      let artefactId: string = window.crypto.randomUUID();
      dispatch(fetchContentCurationThunk(artefactId, ownProps.documentId, primaryQuery, documentContent, formValues, ownProps.authState));
    },
    fetchResponseVariation: (responseContent: string, channels: string[], index: number, formValues: IManageResponseFormValues) => {
      //@ts-ignore
      let artefactId: string = window.crypto.randomUUID();
      dispatch(fetchResponseVariationThunk(artefactId, ownProps.documentId, channels, responseContent, index, formValues, ownProps.authState));
    },
    resetResponseVariation: () => {
      dispatch(new ResetResponseVariationAction());
    },

    setPanvivaDocumentSections: (panvivaDocumentSections: IOrderedSection[])=>{
      dispatch(SetPanvivaDocumentSectionActionThunk(panvivaDocumentSections));
    },
    getKeywords: (formValues: IManageResponseFormValues) => {
      //@ts-ignore
      let artefactId: string = window.crypto.randomUUID();
      dispatch(getKeywordsThunk(artefactId, ownProps.documentId, formValues, ownProps.authState));
    },
    getQueryVariations: (primaryQuery: string, numberToGenerate: number, existingVariations: string[], index: number, formValues: IManageResponseFormValues) => {
      //@ts-ignore
      let artefactId: string = window.crypto.randomUUID();
      dispatch(getQueryVariationsThunk(artefactId, ownProps.documentId, primaryQuery, numberToGenerate, existingVariations, index, formValues, ownProps.authState));
    },
    fetchSummaryResponse: (primaryResponse: string, formValues: IManageResponseFormValues) => {
      //@ts-ignore
      let artefactId: string = window.crypto.randomUUID();
      dispatch(fetchSummaryThunk(artefactId, ownProps.documentId, primaryResponse, formValues, ownProps.authState));
    },
    removeResponseVariationIndex: (index: number) =>{
      dispatch(new RemoveRefreshResponseVariationIndexAction({index: index}));
    },
    resetContentCuration: () => {
      dispatch(new ResetContentCurationAction());
    },
    resetKeywords: () => {
      dispatch(new ResetKeywordsAction());
    },
    resetQueryVariations: () => {
      dispatch(new ResetQueryVariationsAction());
    },
    setUndoSection: (formValues: IManageResponseFormValues) => {
      dispatch(new SetUndoSectionAction({formValues: formValues}));
    },
    resetDocumentSectionUndo: () => {
      dispatch(new ResetDocumentSectionUndoAction());
    },
    setUndoResponse: (formValues: IManageResponseFormValues) => {
      dispatch(new SetUndoResponseAction({ formValues: formValues }));
    },
    setDocumentResponse: (response: string, overrideCurrentResponse: boolean) => {
      dispatch(new SetDocumentResponseAction({ response: response, overrideCurrentResponse: overrideCurrentResponse }));
    },
    resetDocumentResponseUndo: () => {
      dispatch(new ResetDocumentResponseUndoAction());
    }
  };
};

const ManagePage = reduxForm({
  form: manageResponseFormKey,
  enableReinitialize: true,
  onSubmitFail: HandleSubmitFailure,
})(ManageResponsePage);

const hoc = compose(
  withOktaAuth,
  connect<
    IManageResponseStateProps,
    IManagePageDispatchProps,
    IManagePageOwnProps
  >(mapStateToProps, mapDispatchToProps)
)(ManagePage);

export { hoc as ManageResponsePage };