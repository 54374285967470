import { AuthState } from "@okta/okta-auth-js";
import * as React from "react";
import { compositeResponseListDefaultSort, Routes } from "../../../AppConstants";
import appHistory from "../../../AppHistory";
import i18n from "../../../i18n";
import Pagination from "../../../Shared/Pagination/Index";
import './CompositeResponseList.scss';
import { CompositeResponseListRow } from "./CompositeResponseListRow";

export interface IResponseListProps {
  responses: Api.IPagedResult<Api.ICompositeResponseViewModel>;
  docId: number;
  handlePageSizeChange: (pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState | null) => any;
  authState: AuthState | null;
}

class CompositeResponseList extends React.Component<IResponseListProps> {
  constructor(props: IResponseListProps) {
    super(props);

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.sortOrderChanged = this.sortOrderChanged.bind(this);
  }

  public render() {
    const clsName =
    this.props.responses.sortOrder.indexOf("-") > -1
      ? "fa fa-sort-amount-down"
      : "fa fa-sort-amount-up";

    return (
      <div className="composite-response-list">
        <table className="table table-hover table-responsive">
          <thead>
            <tr>
              <th className="title">{i18n.t("responseList.titleColHeading")}</th>
              <th className="status">{i18n.t("responseList.dateColStatus")}</th>
              <th className="category">{i18n.t("responseList.categoryHeading")}</th>
              <th className="date">
                <span className="date-label">{i18n.t("responseList.dateColHeading")}</span>
                <i className={clsName} onClick={this.sortOrderChanged} />
              </th>
            </tr>
          </thead>
          <tbody>
            { this.props.responses.results && this.props.responses.results.map((response, i) => <CompositeResponseListRow response={response} key={i} />) }
          </tbody>
        </table>

        <Pagination onPageSizeChange={this.handlePageSizeChange} pageSize={this.props.responses.pageSize} currentPage={this.props.responses.currentPage} linkUrlLeftPart="/manage/search" totalRecordsNumber={this.props.responses.rowCount} pageCount={this.props.responses.pageCount} authState={this.props.authState} />
      </div>
    )
  }

  private sortOrderChanged(): any {
    let url = `${Routes.manageSearch}/1?docId=${this.props.docId}`;
    if (this.props.responses.sortOrder &&
      this.props.responses.sortOrder.indexOf("-") > -1) {
      url = `${url}&sortOrder=${compositeResponseListDefaultSort}`;
    } else {
      url = `${url}&sortOrder=-${compositeResponseListDefaultSort}`;
    }

    appHistory.push(url);
  }

  private handlePageSizeChange(pageNumber: number, itemPerPage: number) {
    this.props.handlePageSizeChange(
      pageNumber,
      itemPerPage,
      this.props.responses.sortOrder,
      this.props.authState
    );
  }
}

export { CompositeResponseList };
