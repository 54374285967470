import { AuthState } from "@okta/okta-auth-js";
import { Action, Dispatch } from "redux";
import { fetchUsersTenants, getTenantFeatureSettings, updateDefaultTenant } from "./Operations";
import {  Action as AnyAction } from "@reduxjs/toolkit";
import { FeatureSettings } from "./Model";

export enum TenantActionTypes {
  FetchedTenants = "FETCHED_TENANTS",
  FetchedTenantsPending = "FETCHED_TENANTS_PENDING",
  FetchedTenantsRejected = "FETCHED_TENANTS_REJECTED",
  UpdatedTenant = "UPDATED_TENANTS",
  UpdateTenantPending = "UPDATE_TENANTS_PENDING",
  UpdateTenantRejected = "UPDATE_TENANTS_REJECTED",
  FetchedTenantFeatureSettings = "FETCHED_TENANT_FEATURE_SETTINGS",
  FetchedTenantFeatureSettingsPending = "FETCHED_TENANT_FEATURE_SETTINGS_PENDING",
  FetchedTenantFeatureSettingsRejected = "FETCHED_TENANT_FEATURE_SETTINGS_REJECTED"
}

// List tenants
export class FetchingTenantsAction implements Action {
  public readonly type = TenantActionTypes.FetchedTenantsPending
}

export class FetchTenantsFailedAction implements Action {
  public readonly type = TenantActionTypes.FetchedTenantsRejected
  constructor(public payload: { error: string }) {  }
}

export class FetchedTenantsAction implements Action {
  public readonly type = TenantActionTypes.FetchedTenants
  constructor(public payload:  Api.IGetUserTenantResponse ) {  }
}

// Fetch tenants feature settings
export class FetchingTenantFeatureSettingsAction implements Action {
  public readonly type = TenantActionTypes.FetchedTenantFeatureSettingsPending
}

export class FetchedTenantFeatureSettingsFailedAction implements Action {
  public readonly type = TenantActionTypes.FetchedTenantFeatureSettingsRejected
  constructor(public payload: { error: string }) {  }
}

export class FetchedTenantFeatureSettingsAction implements Action {
  public readonly type = TenantActionTypes.FetchedTenantFeatureSettings;
  constructor(public payload: { featureSettings: FeatureSettings }) {  }
}

// Update default tenant
export class UpdatingTenantAction implements Action {
  public readonly type = TenantActionTypes.UpdateTenantPending
}

export class UpdateTenantFailedAction implements Action {
  public readonly type = TenantActionTypes.UpdateTenantRejected
  constructor(public payload: { error: string }) {  }
}

export class UpdatedTenantAction implements Action {
  public readonly type = TenantActionTypes.UpdatedTenant;
  constructor(public payload: { tenant: string }) {  }
}


export type TenantAction = FetchTenantsFailedAction
  | FetchedTenantsAction
  | FetchingTenantsAction
  | UpdateTenantFailedAction
  | UpdatedTenantAction
  | UpdatingTenantAction
  | FetchingTenantFeatureSettingsAction
  | FetchedTenantFeatureSettingsFailedAction
  | FetchedTenantFeatureSettingsAction;


export const getUserTenantsThunk: (auth: AuthState) => any = (auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingTenantsAction());
    return fetchUsersTenants(auth)
      .then((response: Api.IGetUserTenantResponse) => {
        dispatch(new FetchedTenantsAction(response));
        if(response.currentTenant){
          dispatch(getTenantFeatureSettingsThunk(response.currentTenant, auth));
        }
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchTenantsFailedAction({ error: errorResponse.message }));
      });
  }
}

export const updateTenantThunk: (tenant: string, auth: AuthState) => any = (tenant, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new UpdatingTenantAction());
    return updateDefaultTenant(tenant, auth)
      .then((response: Api.IGetUserTenantResponse) => {
        dispatch(new UpdatedTenantAction({tenant}));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new UpdateTenantFailedAction({ error: errorResponse.message }));
      });
  }
}

export const getTenantFeatureSettingsThunk: (tenant: string, auth: AuthState) => any = (tenant, auth) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchingTenantFeatureSettingsAction());
    return getTenantFeatureSettings(tenant, auth)
      .then((response: Api.ITenantFeatureSettingsResponse) => {
        dispatch(new FetchedTenantFeatureSettingsAction({ featureSettings: { enableAIAssistant: response.featureSettings.enableAIAssistant } }));
      })
      .catch((errorResponse: Error) => {
        // tslint:disable-next-line:no-console
        console.error(errorResponse);
        dispatch(new FetchedTenantFeatureSettingsFailedAction({ error: errorResponse.message }));
      });
  }
}



