import { nameof } from "./Shared/Util/NameOf";

export const manageResponseFormKey = "manageForm";
export const responseListDefaultSort = `${nameof<Api.IResponseListViewModel>("dateModified")}`;
export const compositeResponseListDefaultSort = `${nameof<Api.ICompositeResponseViewModel>("dateModified")}`;
export const sectionChangedClass = "has-changes";

const homeRoute = "/home";
const manageRoute = "/manage";
const manageSearchRoute = "/manage/search";
const responseListRoute = `${homeRoute}/responses`;
const draftResponseListRoute = `${homeRoute}/draftresponses`;
const updateListRoute = `${homeRoute}/updates`;
const notFoundRoute = "/not-found";
const errorRoute = "/error";
const logoutRoute = "/logout";
const loginRoute = "/login";
const authenticatingRoute = "/authenticating";
export const Routes = {
  home: homeRoute,
  manage: manageRoute,
  manageSearch: manageSearchRoute,
  responseList: responseListRoute,
  draftResponseList: draftResponseListRoute,
  updateList: updateListRoute,
  notFound: notFoundRoute,
  error: errorRoute,
  login: loginRoute,
  logout: logoutRoute,
  authenticating: authenticatingRoute
}

export const numberOfEmptyRows = 10;
